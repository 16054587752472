import React from "react";
import ClientListSearchv3 from "../FullPages/ClientSearchFullv3";
import CallbackCal from "../FullPages/CallbackCal";
import CalendarFull from "../FullPages/CalendarFull";
import SearchByMissedAppts from "../FullPages/SearchByMissedAppts";
import ProviderClientSearch from "../FullPages/ProviderClientSearch";
import InNetworkProviders from "../FullPages/InNetworkProviders";
import NeedsSupervisor from "../FullPages/NeedsSupervisor";
import LiabilityRequestedList from "../FullPages/LiabilityRequestedList";
import LawfirmPropertyDamage from "../FullPages/LawfirmPropertyDamage";
import LawfirmPolicyLimit from "../FullPages/LawfirmPolicyLimit";
import LawfirmSortByReport from "../FullPages/LawfirmSortByReport";
import LawfirmSortLiability from "../FullPages/LawfirmSortByLiability";
import DocumentSearch from "../FullPages/DocumentSearch";
import NeedsAppointment from "../FullPages/NeedsAppointment";
import ActionItems from "../FullPages/ActionItems";
import LogPhone from "../FullPages/LogPhone";
import LogSMS from "../FullPages/LogSMS";
import AccountabilityLog from "../FullPages/AccountabilityLog";
import { COMPLETE_NO_BILL_PARAM } from "../../../../globalConstants";

export const caseSearch = {
  path: "search",
  title: "Case Search",
  color: "purple",
  component: <ClientListSearchv3 isDashboardVersion />,
  order: 1,
};

export const completeNoBill = {
  path: COMPLETE_NO_BILL_PARAM,
  title: "Complete - No Bill",
  color: "purple",
  component: <ClientListSearchv3 isDashboardVersion completeNoBillFilter />,
  order: 2,
};

export const actionItems = {
  path: "action-items",
  title: "Action Items",
  color: "orange",
  component: <ActionItems />,
  order: 3,
};

export const callbackCalendar = {
  path: "callback-calendar",
  title: "Callback Calendar",
  color: "purple",
  component: <CallbackCal isDashboardVersion />,
  order: 4,
};

export const appointmentCalendar = {
  path: "appointment-calendar",
  title: "Appointment Calendar",
  color: "sky",
  component: <CalendarFull isDashboardVersion />,
  order: 5,
};

export const appointmentsRequiringReview = {
  path: "missed-appts",
  title: "Appointments Requiring Review",
  color: "orange",
  component: <SearchByMissedAppts isDashboardVersion />,
  order: 6,
};

export const needsAppointment = {
  path: "needs-appointment",
  title: "Needs Appointment",
  color: "red",
  component: <NeedsAppointment isDashboardVersion />,
  order: 7,
};

export const providerClientSearch = {
  path: "provider-client-search",
  title: "Provider Client Search",
  color: "purple",
  component: <ProviderClientSearch isDashboardVersion />,
  order: 8,
};

export const inNetworkProviders = {
  path: "in-network-providers",
  title: "In Network Providers",
  color: "green",
  component: <InNetworkProviders isDashboardVersion />,
  order: 9,
};

export const needsSupervisor = {
  path: "needs-supervisor",
  title: "Needs Supervisor",
  color: "red",
  component: <NeedsSupervisor isDashboardVersion />,
  order: 10,
};

export const liabilityRequested = {
  path: "liability-requested",
  title: "Liability Requested",
  color: "orange",
  component: <LiabilityRequestedList isDashboardVersion />,
  order: 11,
};

export const needsPropertyDamageCost = {
  path: "needs-property-damage",
  title: "Needs Property Damage Cost",
  color: "orange",
  component: <LawfirmPropertyDamage isDashboardVersion />,
  order: 12,
};

export const needsPolicyLimit = {
  path: "needs-policy-limit",
  title: "Needs Policy Limit",
  color: "orange",
  component: <LawfirmPolicyLimit />,
  order: 13,
};

export const needsPoliceReport = {
  path: "needs-police-report",
  title: "Needs Police Report",
  color: "orange",
  component: <LawfirmSortByReport isDashboardVersion />,
  order: 14,
};

export const sortByLiability = {
  path: "liability-sort",
  title: "Sort by Liability",
  color: "purple",
  component: <LawfirmSortLiability isDashboardVersion />,
  order: 15,
};

export const documentSearch = {
  path: "document-search",
  title: "Document Search",
  color: "purple",
  component: <DocumentSearch isDashboardVersion />,
  order: 16,
};

export const callLog = {
  path: "phone-log",
  title: "Call Log",
  color: "purple",
  component: <LogPhone />,
  order: 17,
};

export const messageLog = {
  path: "sms-log",
  title: "Message Log",
  color: "purple",
  component: <LogSMS />,
  order: 18,
};

export const actionLog = {
  path: "action-log",
  title: "Action Log",
  color: "red",
  component: <AccountabilityLog isDashboardVersion />,
  order: 19,
};
