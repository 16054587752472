import React, { useContext, useEffect } from "react";
import { Add, AspectRatioOutlined, Remove } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material/";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import renderAvailablePages from "../renderAvailablePages";
import CustomNavChip from "../CustomNavChip";
import { ENTITY_TYPE_PROVIDER } from "../../../../../globalConstants";

export default function GlobalUserDash() {
  const {
    isLoading,
    setLoading,
    caseManagerPk,
    setUserType,
    activePath,
    accessToken,
    setPatchNotifs,
    setRefreshDialog,
    setCurrentPatchV,
    setNeedsUpdate,
    userRoles,
    dashCompLimit,
    setDashCompLimit,
    loggedInUser,
  } = useContext(DataContext);
  const isProvider =
    loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;
  const navigate = useNavigate();

  useEffect(() => {
    if (!caseManagerPk) {
      setLoading(true);
    } else {
      setUserType(Cookies.get("userType"));
      setLoading(false);
    }
  }, [
    accessToken,
    setUserType,
    setCurrentPatchV,
    setRefreshDialog,
    setNeedsUpdate,
    setPatchNotifs,
    setLoading,
    caseManagerPk,
    userRoles.permissions,
  ]);

  const handleNavigateTo = (path) => {
    navigate(`/${path}`);
  };

  const renderShowToggle = () => {
    if (isProvider) {
      return null;
    }
    if (
      dashCompLimit >= renderAvailablePages(userRoles, loggedInUser).length &&
      dashCompLimit > 5
    ) {
      return (
        <Button
          className="normal-case text-gray-600 text-sm"
          onClick={() => setDashCompLimit(5)}
        >
          Show less
          <Remove className="text-sm" />
        </Button>
      );
    }
    return (
      <Button
        className="normal-case text-gray-600 text-sm"
        onClick={() =>
          setDashCompLimit(
            dashCompLimit + renderAvailablePages(userRoles, loggedInUser).length
          )
        }
      >
        Show more <Add className="text-sm" />
      </Button>
    );
  };

  return (
    <>
      {" "}
      {isLoading ? (
        <h1 className="text-center my-10">Loading content...</h1>
      ) : (
        <div className="dark:bg-black flex ">
          <div className="xl:h-screen flex xl:flex-row flex-col w-[100%]">
            <div
              className={`flex lg:flex-col flex-col-reverse w-full h-full overflow-auto`}
            >
              <div className="h-full">
                {renderAvailablePages(userRoles, loggedInUser).length === 0 ? (
                  <div className="h-full flex flex-col justify-center">
                    <h2 className="text-center my-4 text-[1.2rem] font-bold">
                      No available Apps
                    </h2>
                    <p className="text-center">
                      Missing content?{" "}
                      <span className="text-purple-600 font-bold underline">
                        Contact an admin
                      </span>{" "}
                      for assistance.
                    </p>
                  </div>
                ) : (
                  <div className="flex my-2 relative top-0 z-50 bg-white py-2 shadow-sm">
                    <div className="md:pl-[24px] md:w-auto w-[50px]">
                      <Tooltip title={`Navigate to ${activePath}`}>
                        <IconButton
                          onClick={() => handleNavigateTo(activePath)}
                        >
                          <AspectRatioOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div>
                      {renderAvailablePages(userRoles, loggedInUser)
                        .sort((a, b) => a.order - b.order)
                        .slice(0, dashCompLimit)
                        .map((path) => (
                          <CustomNavChip
                            path={path.path}
                            title={path.title}
                            color={path.color}
                            key={path.path}
                          />
                        ))}
                      {renderShowToggle()}
                    </div>
                  </div>
                )}
                {renderAvailablePages(userRoles, loggedInUser)
                  .sort((a, b) => a.order - b.order)
                  .map((comp) => {
                    let activeComp;
                    if (comp.path === activePath) {
                      activeComp = comp.component;
                    }
                    return (
                      <div key={comp?.path} className="lg:max-h-[80vh]">
                        {activeComp}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
