import React from "react";

function AssociateAppointments({ document }) {
  return (
    <div className="grid grid-cols-3 min-h-[65vh] w-full gap-4">
      <div className="col-span-2 min-h-[60vh]">
        {document && document.file
          ? <embed
              aria-label={document.file_name}
              src={document.file}
              width="100%"
              height="100%"
            />
          : <p
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
                fontWeight: "bold"
              }}
            >
              No File Preview Available
            </p>}
      </div>
      <div className="col-span-1">Table Associate Appointments</div>
    </div>
  );
}

export default AssociateAppointments;
