import {
  appointmentCalendar,
  caseSearch,
  appointmentsRequiringReview,
  providerClientSearch,
  callbackCalendar,
  needsAppointment,
  inNetworkProviders,
  needsSupervisor,
  liabilityRequested,
  needsPropertyDamageCost,
  needsPolicyLimit,
  needsPoliceReport,
  sortByLiability,
  documentSearch,
  actionItems,
  callLog,
  messageLog,
  actionLog,
  completeNoBill,
} from "./constant";

import { ENTITY_TYPE_PROVIDER } from "../../../../globalConstants";

export default function renderAvailablePages(userRoles, loggedInUser) {
  const activeArr = [];
  const permissions = userRoles?.permissions ?? [];
  const isProvider =
    loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;

  const addPages = (permissionList, pages) => {
    permissionList.forEach((permission, index) => {
      if (permissions.includes(permission)) {
        activeArr.push(...pages[index]);
      }
    });
  };

  if (isProvider) {
    addPages(
      [
        "casemanager.view_case",
        "provider.view_appointment",
        "provider.view_providerclientstatus",
      ],
      [
        [caseSearch],
        [appointmentCalendar, appointmentsRequiringReview],
        [providerClientSearch],
      ]
    );
    activeArr.push(completeNoBill);
    return activeArr;
  }

  addPages(
    [
      "casemanager.view_case",
      "provider.view_appointment",
      "provider.view_providerclientstatus",
      "provider.view_provider",
      "users.can_view_supervisor_link",
    ],
    [
      [caseSearch, callbackCalendar],
      [appointmentCalendar, appointmentsRequiringReview, needsAppointment],
      [providerClientSearch],
      [inNetworkProviders],
      [needsSupervisor],
    ]
  );

  if (permissions.includes("casemanager.view_case")) {
    activeArr.push(
      liabilityRequested,
      needsPropertyDamageCost,
      needsPolicyLimit,
      needsPoliceReport,
      sortByLiability
    );
    addPages(
      [
        "filemanager.view_document",
        "casemanager.view_actionableitems",
        "call_log.view_calllog",
        "call_log.view_smsmessage",
        "utilities.view_actionlog",
      ],
      [[documentSearch], [actionItems], [callLog], [messageLog], [actionLog]]
    );
  }

  return activeArr;
}
