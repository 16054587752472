import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Link, useNavigate } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import ResponseModal from "../../../../global/ResponseModal";
import PlacesResponseList from "../../../../global/PlacesResponseList";

export default function NonNetworkProvForm() {
  const { accessToken } = useContext(DataContext);
  const [outOfNetworkProv, setOutOfNetworkProv] = useState("");
  const [outOfNetworkPhone, setOutOfNetworkPhone] = useState("");
  const [outOfNetworkType, setOutOfNetworkType] = useState("All");
  const [provLatLng, getProvLatLng] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [address, setAddress] = useState("");
  const [provTypeOptions, setProvTypeOptions] = useState([]);

  const navigate = useNavigate();

  const handlePlaceSelect = async (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => getProvLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOutOfNetworkProv("");
    setAddress("");
    setOutOfNetworkPhone("");
    setOutOfNetworkType("");
    navigate(-1);
  };

  const handleNewProviderSubmit = (e) => {
    const newProvData = {
      name: outOfNetworkProv,
      type: outOfNetworkType,
      address: {
        raw: address,
        latitude: provLatLng.lat,
        longitude: provLatLng.lng,
      },
      phone: outOfNetworkPhone,
      in_network: false,
    };

    axios
      .post("/api/provider/", newProvData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        } else if (error.request) {
        } else {
        }
        setOpenError(!openError);
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.name) {
      arrayVals.push(`provider: ${errorArray.name}`);
    }

    if (errorArray.phone) {
      arrayVals.push(`phone: ${errorArray.phone}`);
    }

    if (errorArray.address) {
      for (const [key, value] of Object.entries(errorArray.address)) {
        arrayVals.push(`${key}: ${value}`);
      }
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  useEffect(() => {
    //Need to sort out why it's not reading the activeClient.client_address only here
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => getProvLatLng(latLng))
      .catch((error) => console.error("Error", error));
  }, [address]);

  useEffect(() => {
    axios
      .get(`/api/field_options/?content_type=provider&ordering=order`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setProvTypeOptions(response.data.results ?? []);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setProvTypeOptions, accessToken]);

  return (
    <div className="mx-10">
      <div className="grid justify-center w-full mt-2">
        <Link
          className="hover:bg-gray-800 hover:text-white w-full px-4 rounded-mdhover:text-white hover:bg-gray-600 text-gray-600 rounded-md flex justify-center border-solid border-gray-600 border w-fit mx-auto px-8 py-1 mt-4"
          onClick={() => navigate(-1)}
        >
          <ArrowBackOutlined />
          Cancel: Back to Assign Provider
        </Link>
      </div>
      <form>
        <TextField
          required
          className="w-full my-4 text-center"
          id="outlined-required"
          label="Out-of-Network Provider Name"
          value={outOfNetworkProv}
          onChange={(event) => {
            setOutOfNetworkProv(event.target.value);
          }}
        />
        <PlacesResponseList
          address={address}
          setAddress={setAddress}
          handlePlaceSelect={handlePlaceSelect}
        />
        <TextField
          className="w-full my-4 text-center"
          id="outlined-required"
          label="Out-of-Network Provider Phone"
          value={outOfNetworkPhone}
          onChange={(event) => {
            setOutOfNetworkPhone(event.target.value);
          }}
        />
        <InputLabel id="select-case-manager">Provider Type</InputLabel>
        <Select
          className="w-full"
          labelId="select-case-manager"
          id="Provider-Type"
          value={outOfNetworkType}
          label="Provider Type"
          onChange={(e) => setOutOfNetworkType(e.target.value)}
        >
          {provTypeOptions.map((opt) => (
            <MenuItem key={opt.order} value={opt.key}>
              {opt.key}
            </MenuItem>
          ))}
        </Select>

        <Button
          className="hover:text-white hover:bg-green-600 text-green-600 rounded-md flex justify-center border-solid border-green-600 border w-fit mx-auto px-8 py-1 mt-4"
          onClick={(e) => {
            handleNewProviderSubmit();
          }}
        >
          Submit New Provider
        </Button>
        <ResponseModal
          title="New Provider Added"
          description={`You have successfully added "${outOfNetworkProv}" to the
          system!`}
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title="Error Adding Provider"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </form>
    </div>
  );
}
